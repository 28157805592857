.view {
  padding: 40px;
  background: #2e3f6e;
  margin: auto;
  margin-bottom: 20px;
  border-radius: 120px;
  width: 120px;
  height: 120px;
}

.plane {
  width: 120px;
  height: 120px;
  transform-style: preserve-3d;
}

.plane.main {
  margin: auto;
  margin-bottom: 40px;
  transform: rotateX(60deg) rotateZ(-30deg);
  -webkit-animation: rotate 20s infinite linear;
  animation: rotate 20s infinite linear;
}

.plane.main .circle {
  width: 120px;
  height: 120px;
  position: absolute;
  transform-style: preserve-3d;
  border-radius: 100%;
  box-sizing: border-box;
  box-shadow: 0 0 60px #4DB7FE, inset 0 0 60px #4DB7FE;
}

.plane.main .circle::before,
.plane.main .circle::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 10%;
  height: 10%;
  border-radius: 100%;
  background: #4DB7FE;
  box-sizing: border-box;
  box-shadow: 0 0 60px 2px #4DB7FE;
}

.plane.main .circle::before {
  transform: translateZ(-90px);
}

.plane.main .circle::after {
  transform: translateZ(90px);
}

.plane.main .circle:nth-child(1) {
  transform: rotateZ(72deg) rotateX(63.435deg);
}

.plane.main .circle:nth-child(2) {
  transform: rotateZ(144deg) rotateX(63.435deg);
}

.plane.main .circle:nth-child(3) {
  transform: rotateZ(216deg) rotateX(63.435deg);
}

.plane.main .circle:nth-child(4) {
  transform: rotateZ(288deg) rotateX(63.435deg);
}

.plane.main .circle:nth-child(5) {
  transform: rotateZ(360deg) rotateX(63.435deg);
}

@-webkit-keyframes rotate {
  0% {
    transform: rotateX(0) rotateY(0) rotateZ(0);
  }

  100% {
    transform: rotateX(360deg) rotateY(360deg) rotateZ(360deg);
  }
}

@keyframes rotate {
  0% {
    transform: rotateX(0) rotateY(0) rotateZ(0);
  }

  100% {
    transform: rotateX(360deg) rotateY(360deg) rotateZ(360deg);
  }
}