body {
  background-color: #f6f6f6;
  overflow: hidden;
  font-family: 'Roboto';
}

.App {
  text-align: center;
  /* background-color: #18141a; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  color: green;
  overflow: hidden;
}

.App-summary {
  padding: 30px 0px 30px 0px;
}

.background_video {
  z-index: 0;
  object-fit: cover;

  &::-webkit-media-controls {
    display: none !important;
  }
}

.video-full-width {
  width: 100%;
}

.video-full-height {
  height: 100%;
}

.app-header {
  z-index: 99;
}

.side-bar {
  padding: 12px;
}

.block-box {
  background: #fff !important;
  border-radius: 10px !important;
  border: 1px solid #e5e7f2 !important;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0), 0px 1px 1px 0px rgba(0, 0, 0, 0), 0px 1px 3px 0px rgba(0, 0, 0, 0) !important;
}

.nft-card {
  transition: transform 0.4s ease !important;
}

.nft-card:hover {
  transform: scale(1.02);
}

.land-status {
  font-weight: 700 !important;
  float: left;
  width: 100%;
  padding-bottom: 5px;
  font-size: 13px !important;
  color: #7d93b2;
}

.land-status:hover {
  color: #4DB7FE;
}

.modal-content {
  max-width: 220px;
  background-color: #f6f6f6;
  padding: 12px;
  border-radius: 12px;
}

.App-link {
  color: #61dafb;
}

.main-container {
  height: '100vh' !important;
  background-color: #10733a;
  justify-content: 'center';
}

.app-bar-logo {
  width: 60px;
  padding: 12px;
}

.main-header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  height: 80px;
  background: #425998;
  z-index: 31;
  -webkit-transform: translate3d(0, 0, 0);
}

.nav-holder {
  float: left;
  top: 18px;
  margin-left: 40px;
  opacity: 1;
  right: 0;
  visibility: visible;
  z-index: 20;
}

.nav-menu-button {
  float: left !important;
  padding: 0 0px !important;
  position: relative !important;
  top: 20px !important;
  left: 20px !important;
  border-radius: 4px !important;
  transition: all .2s ease-in-out !important;
  background-color: #4DB7FE !important;
  height: 40px !important;
  min-width: 40px !important;
}

.app-bar-close {
  top: 20px;
  right: 20px;
  height: 40px !important;
  min-width: 40px !important;
  padding: 0px !important;
  background-color: #4DB7FE !important;
}

.nav-button {
  float: left !important;
  padding: 0 12px !important;
  position: relative !important;
  height: 50px !important;
  top: 15px !important;
  line-height: 40px !important;
  border-radius: 4px !important;
  color: #fff !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  transition: all .2s ease-in-out !important;
  text-transform: none !important;
}

.nav-mobile-button {
  color: #fff !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  transition: all .2s ease-in-out !important;
  text-transform: none !important;
}

.nav-right-button {
  float: right !important;
  padding: 0 22px !important;
  margin: 0 20px !important;
  position: relative;
  height: 40px;
  top: 20px;
  line-height: 40px;
  border-radius: 4px;
  color: #fff !important;
  font-weight: 500;
  font-size: 14px;
  transition: all .2s ease-in-out;
  box-shadow: 0px 0px 0px 6px rgba(255, 255, 255, 0.1);
  background-color: #4DB7FE !important;
}

.nav-button:hover {
  color: #4DB7FE !important;
}

.nav-right-button:hover {
  box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.1);
}

.wallet-connect-button {
  float: right;
  position: relative;
  height: 40px;
  top: 20px;
  margin: 0 20px;
}

.button-icon {
  width: 32px;
  margin-right: 12px;
}

.market-place-content {
  position: absolute;
  top: 80px;
  width: 100%;
  height: calc(100% - 80px);
  overflow: hidden;
}

.pagination {
  position: fixed;
  bottom: 12px;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #fff;
  padding: 8px;
  border-radius: 6px;
  border: 1px solid #e5e7f2;
}

.land-map {}

.land-cell {
  display: flex !important;
  border: 0.5px solid #ccc !important;
  background: #B8860B;
  padding: 0;
  cursor: pointer;
}

.water-cell {
  display: flex !important;
  border: 0.5px solid #4DB7FE !important;
  background: #4DB7FE;
  padding: 0;
  cursor: move;
}


.land-cell:hover {
  border: 0.5px solid #384F95 !important;
}

.map-control-button {
  width: 45px;
  height: 45px;
  border: 1px solid #384F95 !important;
  color: #384F95 !important;
}

.social-control-button {
  width: 32px !important;
  height: 50px;
  border: 1px solid transparent !important;
}

.land-nft-epic {
  width: 100%;
  border-radius: 12px;
}

.title-logo {
  font-size: 72px;
  font-weight: bold;
  color: white;
}

.title-logo-mobile {
  font-size: 48px;
  font-weight: bold;
  color: white;
  margin-bottom: 30px;
}

h3 {
  text-align: left;
  font-size: 15px;
  font-weight: 600;
  color: #566985;
  font-family: 'Nunito', sans-serif;
}

p {
  color: #7d93b2;
  font-size: 12px;
  line-height: 20px;
  max-width: 550px;
  margin: 0 auto;
  font-family: 'Nunito', sans-serif;
}

li span {
  color: #7d93b2;
  font-size: 12px;
  line-height: 20px;
  font-family: 'Nunito', sans-serif;
}

.social-icon {
  width: 32px;
}

.my-asset-content {
  position: absolute;
  top: 80px;
  width: 100%;
  height: calc(100% - 80px);
  overflow: auto;
}

.dvt-token-content {
  position: absolute;
  top: 80px;
  width: 100%;
  height: calc(100% - 80px);
  overflow: auto;
}

.center-container {
  position: inherit;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pagination ul {
  flex-wrap: nowrap !important;
}

.message-content {
  color: #7d93b2;
  font-size: 14px;
  line-height: 20px;
  max-width: 550px;
  margin: 0 auto;
  font-family: 'Nunito', sans-serif;
  min-width: 340px;
}

.input-adjust-button {
  width: 45px;
  font-size: 24px !important;
  border: 1px solid #e5e7f2 !important;
  border-color: #e5e7f2 !important;
  color: #e5e7f2 !important;
}

.button-pulse {
  animation: pulse 2s infinite 3s cubic-bezier(0.25, 0, 0, 1);
  box-shadow: 0 0 0 0 black;
}

.to-top {
  position: fixed;
  bottom: 60px;
  right: 50px;
  width: 40px;
  height: 40px;
  background: #425998;
  color: #4DB7FE;
  font-size: 17px;
  z-index: 116;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.2);
  -webkit-transform: translate3d(0, 0, 0);
}

.to-top:hover {
  background: #2e3f6e;
}
.to-top-button {
  min-width: 40px !important;
  height: 40px !important;
  color: #4DB7FE !important;
}

@keyframes pulse {
  to {
    box-shadow: 0 0 0 16px rgba(255, 255, 255, 0);
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4DB7FE;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2e3f6e;
}

/*------ loader-------------------------------------------------*/
.loader-wrap {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 240px;
  z-index: 100;
  background: #2e3f6e;
  border-radius: 12px;
  -webkit-transform: translate3d(0, 0, 0);
}

.page-loader-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: #2e3f6e;
  -webkit-transform: translate3d(0, 0, 0);
}

.loader-inner {
  z-index: 100;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 32px;
  height: 32px;
  animation: counterClockwise 4s linear infinite;
  margin: -16px 0 0 -16px;
  border: 2px solid #4DB7FE;
  border-radius: 100%;
}

.loader-inner:before,
.loader-inner:after {
  content: '';
  position: absolute;
  border-radius: 100%;
}

.loader-inner:before {
  width: 8px;
  height: 8px;
  background: #fff;
  animation: clockwise 4s linear infinite;
  top: -4px;
}

.loader-inner:after {
  width: 4px;
  height: 4px;
  top: 4px;
  left: 22px;
  background: #4DB7FE;
  transform-origin: -4px -4px;
  animation: counterClockwise 1s linear infinite;
}

.loader-9::before,
.loader-9::after {
  border-radius: 50%;
}

@-webkit-keyframes clockwise {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes clockwise {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@-webkit-keyframes counterClockwise {
  to {
    -webkit-transform: rotate(-1turn);
    transform: rotate(-1turn);
  }
}

@keyframes counterClockwise {
  to {
    -webkit-transform: rotate(-1turn);
    transform: rotate(-1turn);
  }
}